<template>
  <div>
    <a-modal width="700px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form ref="formRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" :model="formState" :rules="rules">
        <a-form-item label="名称：" name="name">
          <a-input v-model:value="formState.name" style="width: 370px" />
        </a-form-item>
        <a-form-item label="描述：" name="description">
          <a-input v-model:value="formState.description" style="width: 370px" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { DictController_post, DictController_put } from '@/api/system'
const form = {
  name: '',
  categoryId: ''
}
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      visible: false,
      formState: {
        ...form
      },
      rules: {
        name: [{ required: true, message: '不能为空', trigger: 'change' }]
      },
      editor: false
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        if (!this.editor) {
          let obj = {
            name: this.formState.name,
            description: this.formState.description
          }
          DictController_post(obj).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            this.$parent.refresh()
          })
        } else {
          let obj = {
            name: this.formState.name,
            description: this.formState.description,
            dictId: this.formState.dictId
          }
          DictController_put(obj).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            this.$parent.refresh()
          })
        }
      })
    },
    showModal(val) {
      this.visible = true
      if (val) {
        this.editor = true
        console.log(val, '编辑')
        this.formState = { ...form, ...val }
        console.log(this.formState)
      } else {
        this.editor = false
      }
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
    